import moment from "moment";

function htmlEscape(string) {
  return string
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) {
      const returnValue = c.substring(nameEQ.length, c.length);

      if (returnValue == "true") return true;
      else if (returnValue == "false") return false;

      return returnValue;
    }
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

function links2html(text) {
  const rx = /([^"']|^)((http|https):\/\/[^\s\r\n\0\<\['"]+)/gi;
  return text.replace(rx, function ($0, $1, $2) {
    return $1 + '<a href="' + $2 + '">' + $2 + "</a>";
  });
}

function mail2html(text) {
  const rx =
    /(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})/gi;
  return text.replace(rx, function ($0) {
    return '<a href="mailto:' + $0 + '">' + $0 + "</a>";
  });
}

function removeMagnetLinks(text) {
  return text.replace(
    /('|")?((magnet|dchub|adc):[^"'\s\r\n\0\<]+["'\s\r\n\0\<]+)/g,
    function ($0, $1, $2) {
      return '"https://talkvio.com"';
    },
  );
}

const trMap = {
  Ё: "YO",
  Й: "I",
  Ц: "TS",
  У: "U",
  К: "K",
  Е: "E",
  Н: "N",
  Г: "G",
  Ш: "SH",
  Щ: "SCH",
  З: "Z",
  Х: "H",
  Ъ: "'",
  ё: "yo",
  й: "i",
  ц: "ts",
  у: "u",
  к: "k",
  е: "e",
  н: "n",
  г: "g",
  ш: "sh",
  щ: "sch",
  з: "z",
  х: "h",
  ъ: "'",
  Ф: "F",
  Ы: "I",
  В: "V",
  А: "A",
  П: "P",
  Р: "R",
  О: "O",
  Л: "L",
  Д: "D",
  Ж: "ZH",
  Э: "E",
  ф: "f",
  ы: "i",
  в: "v",
  а: "a",
  п: "p",
  р: "r",
  о: "o",
  л: "l",
  д: "d",
  ж: "zh",
  э: "e",
  Я: "Ya",
  Ч: "CH",
  С: "S",
  М: "M",
  И: "I",
  Т: "T",
  Ь: "'",
  Б: "B",
  Ю: "YU",
  я: "ya",
  ч: "ch",
  с: "s",
  м: "m",
  и: "i",
  т: "t",
  ь: "'",
  б: "b",
  ю: "yu",
};

function transliterate(word) {
  return word
    .split("")
    .map(function (char) {
      return trMap[char] || char;
    })
    .join("");
}

function transliterateLink(word) {
  if (!word) {
    return "";
  }
  return transliterate(word)
    .trim()
    .replace(/ +(?= )/g, "")
    .replaceAll(" ", "-")
    .replace(/[^a-zA-Z0-9-_]/g, "")
    .toLowerCase();
}

function dateObjectToDate(obj) {
  if (!obj) {
    return null;
  }
  return new Date(obj.year, obj.month - 1, obj.day, obj.hour, obj.minute);
}

function dateToDateObject(obj) {
  if (!obj) {
    return null;
  }
  return {
    year: obj.getFullYear(),
    month: obj.getMonth() + 1,
    day: obj.getDate(),
    hour: obj.getHours(),
    minute: obj.getMinutes(),
  };
}

function addHeaderTagOnce(
  prop = "",
  value = "",
  propProp = "property",
  valueProp = "content",
  tag = "meta",
) {
  let existingTag = document.querySelector(`${tag}[${propProp}="${prop}"]`);
  if (!existingTag) {
    let tagObj = document.createElement(tag);
    tagObj.setAttribute(propProp, prop);
    tagObj.setAttribute(valueProp, value);
    document.getElementsByTagName("head")[0].appendChild(tagObj);
  } else {
    existingTag.setAttribute(valueProp, value);
  }
}

function removeHeaderTag(prop, propProp, tag = "meta") {
  let metaToRemove = document.querySelector(`${tag}[${propProp}="${prop}"]`);
  if (metaToRemove) {
    metaToRemove.parentNode.removeChild(metaToRemove);
  }
}

function cutLexicalString(inputString, maxLength, finishLine = "") {
  // Check if the inputString is already shorter than the maxLength
  if (inputString.length <= maxLength) {
    return inputString;
  }

  maxLength -= finishLine.length;

  // Find the last space within the maxLength
  let lastSpaceIndex = inputString.lastIndexOf(" ", maxLength);

  // If no space is found, cut at the exact maxLength
  if (lastSpaceIndex === -1) {
    return inputString.slice(0, maxLength) + finishLine;
  }

  // Otherwise, cut at the last space within the maxLength
  return inputString.slice(0, lastSpaceIndex) + finishLine;
}

const mobileCheck = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const isMobile = () => {
  return navigator.userAgentData?.mobile || mobileCheck();
};

function getRussianEnding(number, forms) {
  number = Math.abs(number) % 100;
  let lastDigit = number % 10;

  if (number > 10 && number < 20) {
    return forms[2]; // 11-19: always use the third form
  }
  if (lastDigit > 1 && lastDigit < 5) {
    return forms[1]; // 2-4: use the second form
  }
  if (lastDigit == 1) {
    return forms[0]; // 1: use the first form
  }
  return forms[2]; // 5-9, 0: use the third form
}

function timeAgo(date) {
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);

  if (seconds < 0) {
    return moment(date).format("D MMM YYYY в HH:mm");
  }

  if (seconds < 60) return `${seconds} сек`; // seconds ago
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) return `${Math.max(1, minutes)} мин`; // minutes ago
  const hours = Math.floor(minutes / 60);
  if (hours < 24)
    return `${Math.max(1, hours)} ${getRussianEnding(Math.max(1, hours), ["час", "часа", "часов"])}`; // hours ago
  const days = Math.floor(hours / 24);
  if (days === 1) return `1 день`; // 1 day ago
  if (days < 7)
    return `${Math.max(1, days)} ${getRussianEnding(Math.max(1, days), ["день", "дня", "дней"])}`; // days ago
  const weeks = Math.floor(days / 7);
  if (weeks < 4)
    return `${Math.max(1, weeks)} ${getRussianEnding(Math.max(1, weeks), ["неделя", "недели", "недель"])}`; // weeks ago
  const months = Math.floor(days / 30);
  if (months < 12)
    return `${Math.max(1, months)} ${getRussianEnding(Math.max(1, months), ["месяц", "месяца", "месяцев"])}`; // months ago
  const years = Math.floor(days / 365);
  return `${Math.max(1, years)} ${getRussianEnding(Math.max(1, years), ["год", "года", "лет"])}`; // years ago
}

export {
  htmlEscape,
  setCookie,
  getCookie,
  removeMagnetLinks,
  eraseCookie,
  links2html,
  mail2html,
  transliterate,
  transliterateLink,
  dateObjectToDate,
  dateToDateObject,
  addHeaderTagOnce,
  removeHeaderTag,
  cutLexicalString,
  isMobile,
  timeAgo,
};
