import React, { useState, useEffect, useContext, useRef } from "react";
import { LoginContext } from "./Login";
import Post from "./Post";
import { Link } from "react-router-dom";
import AutoScrollable from "../global/AutoScrollable";
import { getCookie, setCookie } from "../global/Global";
import useDidUpdateEffect from "../global/DidUpdateEffect";
import HistoryScroll, { setScroll } from "../global/HistoryScroll";
import Filters from "./Filters";
import ReplyForm from "./ReplyForm";
import _ from "lodash";
import {
  faEye,
  faEyeSlash,
  faHurricane,
  faRetweet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RecentPosts = ({
  noLimitation = false,
  forumid = 0,
  noFetch = false,
  popular = false,
  random = false,
  cleverPopular = false,
  leftRatingDisplay = false,
  targetUserId = null,
  search = null,
  onlyOriginal = false,
  onlySubscriptions = false,
  onlyTagSubscriptions = false,
  onlyAnswers = false,
  onlyMyComments = false,
  recentPopularSwitcher = false,
  fullTimePeriod = false,
  oldUnpopularSwitcher = false,
  mostCommented = false,
  periodSwitcher = false,
  tag = null,
  leftPanels = null,
  sticky = false,
  stickyShow = false,
  subscriptions = null,
  subscriptionsTags = null,
  customScrollData = null,
  onCustomScrollDataRestore = null,
  bookmarks = false,
  displayOnlyPosts = false,
  displayOnlyComments = false,
  origin = null,
  showReply = false,
  delayedPosts = false,
  source = "",
}) => {
  let _displayMode = getCookie("displaymode");
  if (typeof _displayMode == "undefined" || _displayMode === null) {
    _displayMode = 1;
  }

  const [posts, setPosts] = useState([]);
  let [postsMap, setPostsMap] = useState({});
  const [displayMode, setDisplayMode] = useState(_displayMode);
  const { user } = useContext(LoginContext);
  let currentCategory = getCookie("categoryswitcher") || 0;
  const [categorySwitcher, setCategorySwitcher] = useState(currentCategory);
  const [postsPeriod, setPostsPeriod] = useState(getCookie("postsperiod") || 3);
  const [tagSubscribed, setTagSubscribed] = useState(false);
  const [tagStatusLoaded, setTagStatusLoaded] = useState(false);
  const [replyAfterPostid, setReplyAfterPostid] = useState(0);
  const [replyPostEnabled, setReplyPostEnabled] = useState(true);
  const [ignoreHidden, setIgnoreHidden] = useState(false);

  const uncollapseMap = useRef({});
  const textFullQuoteMap = useRef({});
  const [hiddenMap, setHiddenMap] = useState({});
  const collapsedScrollValue = useRef(-1);
  const postsListsRef = useRef();
  const needCheckIgnorePosts = useRef(false);

  useDidUpdateEffect(() => {
    if (!targetUserId || targetUserId == 0) return;

    logT("recentposts", "changed targetuserid", targetUserId);
    fetchData(true);
  }, [targetUserId]);

  useDidUpdateEffect(() => {
    logT(
      "recentposts",
      "change subscriptions",
      subscriptions,
      "tags",
      subscriptionsTags,
    );
    fetchData(true);
  }, [
    onlySubscriptions,
    onlyTagSubscriptions,
    subscriptions,
    subscriptionsTags,
  ]);

  useDidUpdateEffect(() => {
    if (!tag || tag == "") return;

    logT("recentposts", "change tag", tag);
    fetchData(true);
  }, [tag]);

  useDidUpdateEffect(() => {
    logT("ignore", "changed ignore value", ignoreHidden, "rerender");
    fetchData(true);
  }, [ignoreHidden]);

  const scrollUpdateCollapsed = () => {
    if (collapsedScrollValue.current >= 0) {
      setScroll("recentposts", "CollapsedPosts", collapsedScrollValue.current);
      collapsedScrollValue.current = -1;
    }
  };

  const rebuldPostMap = () => {
    Object.keys(postsMap).forEach((key) => {
      const post = postsMap[key];
      if (post.parentid && postsMap[post.parentid]) {
        post.parent = postsMap[post.parentid];
        if (!post.parent.childs) post.parent.childs = {};
        post.parent.childs[post.postid] = post;
      }
    });
    setPostsMap(Object.assign({}, postsMap));
  };

  const fetchData = async (full = false) => {
    if (posts && posts.length > 1000) {
      logT("recentposts", "too much posts");
      return;
    }

    if (targetUserId == -1) {
      logT(
        "recentposts",
        "skip load data, because targetUserId = -1 considered as unset",
      );
      return;
    }

    let index = (posts && posts.length) || 0;
    let limit = noLimitation ? 20 : sticky ? 10 : 2;
    if (full) {
      index = 0;
      if (noLimitation) limit = Math.max(20, posts.length + 1);
      else limit = sticky ? 10 : 2;
    }
    let token;
    let userid;
    if (leftRatingDisplay || tag) {
      token = getCookie("token") || "";
      userid = parseInt(getCookie("userid")) || 0;
    }
    let _displayMode = getCookie("displaymode");
    if (typeof _displayMode == "undefined" || _displayMode === null) {
      _displayMode = 1;
    }
    currentCategory = getCookie("categoryswitcher") || 0;
    let period;
    if (mostCommented || periodSwitcher) {
      period = parseInt(getCookie("postsperiod") || 3);
    } else {
      period = 3;
    }

    if (
      onlySubscriptions &&
      Array.isArray(subscriptions) &&
      subscriptions.length == 0
    ) {
      return;
    }

    if (
      onlySubscriptions &&
      Array.isArray(subscriptionsTags) &&
      subscriptionsTags.length == 0
    ) {
      return;
    }

    let data;
    if (!search) {
      data = await window.TALKVIOAPI(
        "posts",
        {
          popular: (recentPopularSwitcher && currentCategory == 1) || popular,
          random: random,
          cleverPopular,
          recent: !oldUnpopularSwitcher || currentCategory != 2,
          unpopular: oldUnpopularSwitcher && currentCategory == 3,
          mostCommented,
          index,
          limit,
          userid,
          token,
          targetUserId: parseInt(targetUserId) || 0,
          onlyPosts:
            !sticky &&
            ((_displayMode == 1 && !displayOnlyComments) || displayOnlyPosts),
          onlyComments:
            !sticky &&
            ((_displayMode == 2 && !displayOnlyPosts) || displayOnlyComments),
          onlyOriginal,
          onlySubscriptions,
          onlyTagSubscriptions,
          onlyAnswers,
          onlyMyComments,
          fullTimePeriod,
          period,
          tag: tag || "",
          subscriptions: subscriptions || [],
          subscriptionsTags: subscriptionsTags || [],
          bookmarks,
          forumid: parseInt(forumid) || 0,
          noPostMap: !!sticky,
          delayedPosts,
          source,
          ignoreHidden,
        },
        { tag: "recentposts", mark: sticky ? "sticky" : null },
      );
    } else {
      data = await window.TALKVIOAPI("search", {
        text: search,
        index,
        limit,
      });
    }
    if (!data) {
      logT("posts", "no data responce");
      return;
    }
    scrollUpdateCollapsed();

    needCheckIgnorePosts.current = data.needCheckIgnorePosts;

    if (data.posts?.length > 0 || full) {
      // build postmap
      Object.keys(data.postsMap).forEach((key) => {
        // prefer posts over postMap and keep postMap always filled with posts
        if (full || !postsMap[key]) postsMap[key] = data.postsMap[key];
      });
      data.posts.forEach((post) => (postsMap[post.postid] = post));
      rebuldPostMap();

      if (!full) setPosts((posts || []).concat(data.posts));
      else setPosts(data.posts);
    } else {
      logT("posts", "nothing to load, 0 new posts, ignore posts update");
    }

    if (tag?.length > 0 && userid > 0) {
      const subs = await window.TALKVIOAPI(
        "subscriptions",
        {
          token,
          userid,
          subscriptionsUsers: false,
          subscriptionsTags: true,
        },
        { tag: "subscriptions" },
      );
      const tags = subs?.tags?.map((tag) => tag.tagtext);
      if (tags?.includes(tag)) {
        logT("tag", "subscribed to tag", tag);
        setTagSubscribed(true);
      }
      setTagStatusLoaded(true);
    }
  };

  window.TALKVIO_ON(
    "addPost",
    (post) => {
      if (sticky && post.private) {
        logT("sticky", "ignore private post");
        return;
      }

      if (noFetch) {
        logT("noFetch", "ignore posts fetching, using sockets");
        if (
          user?.blockusers
            ?.split(",")
            .map((i) => i | 0)
            .includes(post.userid)
        ) {
          logT("noFetch", "dont show this post as blocked user", post.userid);
          return;
        }
        if (
          user?.blocktags
            ?.split(",")
            .some((tag) => post.taglist?.split(", ").includes(tag))
        ) {
          logT("noFetch", "dont show this post as blocked tags", post.taglist);
          return;
        }
        let newPosts = Array.from(posts);
        newPosts.unshift(post);
        postsMap[post.postid] = post;
        if (sticky && newPosts.length > 10) {
          const removedPost = newPosts.pop();
          // erase from posts map
          if (removedPost.childs) {
            Object.values(removedPost.childs).forEach((p) => delete p.parent);
          }
          delete removedPost.parent?.childs?.[removedPost.postid];
          delete postsMap[removedPost.postid];
        }
        rebuldPostMap();
        logT("noFetch", "insert new post to posts", newPosts);
        setPosts(newPosts);

        return;
      }

      // limit load on server on new comments on for recent pages are allowed
      if (
        post.userid == user?.userid ||
        onlyAnswers ||
        (!random &&
          !popular &&
          (!recentPopularSwitcher || currentCategory == 0)) // recent
      ) {
        if (onlyAnswers) {
          if (
            post.userid == user?.userid ||
            (post.parentid > 0 &&
              (post.threaduserid == user?.userid ||
                post.parentuserid == user?.userid))
          ) {
            logT(
              "socket",
              "full post list need reloaded for this recentview (answers)",
            );
            fetchData(true);
          }
        } else {
          logT(
            "socket",
            "full post list need reloaded for this recentview (common)",
          );
          fetchData(true);
        }
      }
    },
    "recentPosts" + (sticky ? "Sticky" : ""),
  );

  if (!sticky && !noFetch) {
    window.TALKVIO_ON(
      "prepublishPost",
      (post) => {
        logT("prepublish", "receving post prepublish postid =", post.postid);
        fetchData(true);
      },
      "recentPosts" + (sticky ? "Sticky" : ""),
    );
  }

  window.TALKVIO_ON(
    "deletePost",
    (post) => {
      for (const p of posts) {
        if (p.postid == post.postid) {
          fetchData(true);
          break;
        }
      }
    },
    "recentPosts" + (sticky ? "Sticky" : ""),
  );

  window.TALKVIO_ON(
    "editPost",
    (post) => {
      for (const p of posts) {
        if (p.postid == post.postid || p.parentid == post.postid) {
          fetchData(true);
          break;
        }
      }
    },
    "recentPosts" + (sticky ? "Sticky" : ""),
  );

  window.TALKVIO_ON(
    "changePostRating",
    (post) => {
      const p = postsMap[post.postid];
      if (p) {
        // don't refetch because we don't want change order of post on rating change
        p.postrating = post.postrating;
        p.rating_all = post.rating_all;
        p.rating_bad = post.rating_bad;
        p.rating_good = post.rating_good;
        if (user?.userid == post.userid) {
          if (post.changeRating) {
            p.setrating = null;
          } else {
            if (post.ratingReduce) {
              p.setrating = "-";
            } else {
              p.setrating = "+";
            }
          }
          logT(
            "rating",
            "current user change rating",
            "change =",
            post.changeRating,
            "reduce =",
            post.ratingReduce,
          );
        }
        setPosts(posts.slice());
      }
    },
    "recentPosts" + (sticky ? "Sticky" : ""),
  );

  window.TALKVIO_ON(
    "changedSettings",
    (u) => {
      if (u.userid == user?.userid) {
        fetchData(true);
      }
    },
    "recentPosts" + (sticky ? "Sticky" : ""),
  );

  window.TALKVIO_ON(
    "subscription",
    (sub) => {
      for (const p of posts) {
        if (p.userid == sub.targetuserid) {
          fetchData(true);
          break;
        }
      }
    },
    "recentPosts" + (sticky ? "Sticky" : ""),
  );

  window.TALKVIO_ON(
    "subscriptionThread",
    (sub) => {
      if (sub.userid != user?.userid) {
        return;
      }
      for (const p of posts) {
        if (p.threadid == sub.threadid) {
          fetchData(true);
          break;
        }
      }
    },
    "recentPosts" + (sticky ? "Sticky" : ""),
  );

  window.TALKVIO_ON(
    "bookmark",
    (bookmark) => {
      if (bookmark.userid != user?.userid) {
        return;
      }
      for (const p of posts) {
        if (p.postid == bookmark.postid) {
          fetchData(true);
          break;
        }
      }
    },
    "recentPosts" + (sticky ? "Sticky" : ""),
  );

  window.TALKVIO_ON(
    "raisePost",
    (post) => {
      for (const p of posts) {
        if (p.postid == post.postid) {
          logT("socket", "raise post update", post.postid);
          fetchData(true);
          break;
        }
      }
    },
    "recentPosts" + (sticky ? "Sticky" : ""),
  );

  window.TALKVIO_ON(
    "updateTags",
    (post) => {
      for (const p of posts) {
        if (p.postid == post.postid) {
          logT("socket", "update post tags", post.postid, "tags", post.tags);
          fetchData(true);
          break;
        }
      }
    },
    "recentPosts" + (sticky ? "Sticky" : ""),
  );

  window.TALKVIO_ON(
    "updatePostForum",
    (post) => {
      for (const p of posts) {
        if (p.postid == post.postid) {
          logT("socket", "update forum", post.postid, "forum", post.forumid);
          fetchData(true);
          break;
        }
      }
    },
    "recentPosts" + (sticky ? "Sticky" : ""),
  );

  window.TALKVIO_ON(
    "blockUser",
    (u) => {
      if (u.userid == user?.userid) {
        logT("socket", "userBlock", u.userid);
        fetchData(true);
      }
    },
    "recentPosts" + (sticky ? "Sticky" : ""),
  );

  window.TALKVIO_ON(
    "fileFinished",
    (attachment) => {
      for (const p of posts) {
        if (p.postid == attachment.contentid) {
          logT("socket", "file processed finished", p.postid);
          fetchData(true);
          break;
        }
      }
    },
    "recentPosts" + (sticky ? "Sticky" : ""),
  );

  useEffect(() => {
    if (sticky) return; // don't load inital posts, call on resize

    logT("RecentPosts", "load posts");
    fetchData();
    // settings of panel
    const ALIGN_TOP = 64;

    const previosPost = () => {
      const scroll = document.documentElement.scrollTop;
      if (!postsListsRef?.current) return;

      const childElements = postsListsRef.current?.children;
      if (childElements?.length > 0) {
        for (let i = childElements.length - 1; i >= 0; i--) {
          const child = childElements[i];
          const elementPosition = child.getBoundingClientRect();
          if (elementPosition.top < -100) {
            logT(
              "key",
              "scroll to previus post",
              i,
              elementPosition.top,
              scroll,
            );
            window.scrollTo({
              top: scroll + elementPosition.top - ALIGN_TOP,
              behavior: "smooth",
            });
            return;
          }
        }
      }
    };
    const nextPost = () => {
      const scroll = document.documentElement.scrollTop;
      if (!postsListsRef?.current) return;

      const childElements = postsListsRef.current?.children;
      if (childElements?.length > 0) {
        for (let i = 0; i < childElements.length; i++) {
          const child = childElements[i];
          const elementPosition = child.getBoundingClientRect();
          if (elementPosition.top > 100) {
            logT("key", "scroll to next post", i, elementPosition.top, scroll);
            window.scrollTo({
              top:
                scroll +
                elementPosition.top -
                ALIGN_TOP +
                (!window.talkvioHeaderFixed ? ALIGN_TOP : 0),
              behavior: "smooth",
            });
            return;
          }
        }
      }
    };
    const findCurrentScrollPost = () => {
      const scroll = document.documentElement.scrollTop;
      if (!postsListsRef?.current) return;

      const childElements = postsListsRef.current?.children;
      if (childElements?.length > 0) {
        for (let i = 0; i < childElements.length; i++) {
          const child = childElements[i];
          const elementPosition = child.getBoundingClientRect();
          if (
            Math.abs(
              elementPosition.top -
                ALIGN_TOP +
                (window.talkvioHeaderOffset || 0),
            ) < 40
          ) {
            const postid = child.getAttribute("data-postid");
            logT(
              "key",
              "post find",
              postid,
              "align",
              elementPosition.top -
                ALIGN_TOP +
                (window.talkvioHeaderOffset || 0),
            );
            return postid;
          }
        }
      }
    };
    let blocking = false;
    const upPost = async () => {
      if (blocking) return;

      const postid = parseInt(findCurrentScrollPost());
      if (postid) {
        logT("rating", "change up postid", postid);
        const token = getCookie("token");
        const userid = parseInt(getCookie("userid"));
        if (userid && token) {
          blocking = true;
          const data = await window.TALKVIOAPI("changePostRating", {
            userid,
            token,
            postid: postid,
            reduce: false,
          });
          blocking = false;
          window.displayError(data);
        } else {
          if (window.needRegistationAction) window.needRegistationAction();
        }
      }
    };
    const downPost = async () => {
      if (blocking) return;

      const postid = parseInt(findCurrentScrollPost());
      if (postid) {
        logT("rating", "change down postid", postid);
        const token = getCookie("token");
        const userid = parseInt(getCookie("userid"));
        if (userid && token) {
          blocking = true;
          const data = await window.TALKVIOAPI("changePostRating", {
            userid,
            token,
            postid: postid,
            reduce: true,
          });
          blocking = false;
          window.displayError(data);
        } else {
          if (window.needRegistationAction) window.needRegistationAction();
        }
      }
    };

    if (!sticky) {
      window.KEY_ON("d", nextPost, "posts");
      window.KEY_ON("в", nextPost, "posts");
      window.KEY_ON("a", previosPost, "posts");
      window.KEY_ON("ф", previosPost, "posts");
      window.KEY_ON("w", upPost, "posts");
      window.KEY_ON("ц", upPost, "posts");
      window.KEY_ON("s", downPost, "posts");
      window.KEY_ON("ы", downPost, "posts");
    }

    if (origin == "user") window.setLeftPanelTopOffet(55);
    else window.setLeftPanelTopOffet(120);
    window.setLeftPanelDisplay(true);
    if (origin == "subscriptions") window.setLeftPanelOnSize(1770);

    if (origin == "user") window.setRightPanelTopOffet(42);
    else window.setRightPanelTopOffet(120);
    window.setRightPanelDisplay(true);
    if (origin == "subscriptions") window.setRightPanelOnSize(1600);

    return () => {
      window.resetLeftPanelTopOffet();
      window.setLeftPanelDisplay(false);
      window.setLeftPanelOnSize();

      window.resetRightPanelTopOffet();
      window.setRightPanelDisplay(false);
      window.setRightPanelOnSize();

      if (!sticky) {
        window.KEY_OFF("d", nextPost);
        window.KEY_OFF("в", nextPost);
        window.KEY_OFF("a", previosPost);
        window.KEY_OFF("ф", previosPost);
        window.KEY_OFF("w", upPost);
        window.KEY_OFF("ц", upPost);
        window.KEY_OFF("s", downPost);
        window.KEY_OFF("ы", downPost);
      }
    };
  }, []);

  useEffect(() => {
    if (!search) return;

    posts.splice(0, posts.length);
    fetchData(true);
  }, [search]);

  useEffect(() => {
    if (!sticky) return;

    if (stickyShow && posts.length == 0) {
      logT("sticky", "resize load sticky posts fetch trigger");
      fetchData();
    }
  }, [stickyShow]);

  const showPosts = () => (
    <ul
      className={"recentposts posts postslist" + (sticky ? " sticky" : "")}
      ref={postsListsRef}
    >
      {!sticky &&
      user?.settings?.hideViewHistory &&
      !user?.settings?.hideViewHistoryCollapse &&
      (user?.settings?.hideViewHistoryAllPages || cleverPopular) &&
      (user?.viewhistory?.posts || posts.some((post) => post.isHidden)) ? (
        <>
          <li
            className="resetViewHistory typicalBlock"
            onClick={async () => {
              const token = getCookie("token");
              const userid = parseInt(getCookie("userid"));
              const data = await window.TALKVIOAPI("setSettings", {
                userid,
                token,
                settings: {
                  hideViewHistoryReset: true,
                },
              });
            }}
          >
            <FontAwesomeIcon icon={faHurricane} />
            <div>{__("Press to erase list of hidden posts")}</div>
          </li>
          <li
            className="showViewHistory typicalBlock"
            onClick={async () => {
              setIgnoreHidden(!ignoreHidden);
            }}
          >
            <FontAwesomeIcon icon={ignoreHidden ? faEyeSlash : faEye} />
            <div>
              {ignoreHidden
                ? __("Press to unsee hidden posts")
                : __("Press to see hidden posts")}{" "}
              (
              {Math.max(
                user?.viewhistory?.posts
                  ? Object.keys(user?.viewhistory?.posts).length
                  : 0,
                posts.filter((post) => post.isHidden).length,
              )}
              )
            </div>
          </li>
        </>
      ) : null}
      {posts.map((post, postIndex) => (
        <li key={post.postid} data-postid={post.postid}>
          <ul className="subpostslist">
            <>
              <Post
                source="recentposts"
                collapsable={true}
                post={post}
                key={post.postid}
                user={user}
                forum={
                  post.forumid
                    ? { forumid: post.forumid, title: post.forumtitle }
                    : null
                }
                fetchPosts={fetchData}
                leftRatingDisplay={leftRatingDisplay && !sticky}
                forceTitle={true}
                minimal={true}
                postLink={sticky}
                maxTextSize={sticky ? 160 : 0}
                sticky={sticky}
                onReplyClick={(postid) => {
                  setReplyAfterPostid(postid);
                  setReplyPostEnabled(true);
                }}
                showReply={showReply}
                onCollapsed={() => scrollUpdateCollapsed()}
                onUncollapse={(uncollapse) => {
                  if (!uncollapseMap?.current) return;
                  uncollapseMap.current[post.postid] = uncollapse;
                  logT(
                    "collapse",
                    "update collapse map",
                    uncollapseMap.current,
                  );
                }}
                uncollapse={uncollapseMap?.current?.[post.postid]}
                onTextFullQuote={(fullquote) => {
                  if (!textFullQuoteMap?.current) return;
                  textFullQuoteMap.current[post.postid] = fullquote;
                  logT("quote", "update quote map", textFullQuoteMap.current);
                }}
                textFullQuote={textFullQuoteMap?.current?.[post.postid]}
                setHidden={(hide) => {
                  hiddenMap[post.postid] = hide;
                  setHiddenMap(Object.assign({}, hiddenMap));
                  logT("hidden", "update hidden map", hiddenMap);
                }}
                hidden={
                  typeof hiddenMap?.[post.postid] != "undefined"
                    ? hiddenMap?.[post.postid]
                    : post.isHidden
                }
                onNextPostScroll={() => {
                  let nextPost = posts[postIndex + 1];
                  if (!nextPost) return;
                  const scrollEl = document.getElementById(
                    `post_${nextPost.postid}`,
                  );
                  if (scrollEl) {
                    const position =
                      scrollEl.getBoundingClientRect().top + window.scrollY;
                    logT(
                      "nextPost",
                      "scroll next post",
                      nextPost.postid,
                      "position",
                      position,
                    );
                    window.scrollTo({ top: position, behavior: "smooth" });
                  }
                }}
              />
              {(showReply && replyAfterPostid == post.postid) ||
              (user?.settings?.feedReplies && post.parentid == 0 && !sticky) ? (
                <ReplyForm
                  key={"reply-post-" + post.postid}
                  className="typicalBlock"
                  replyPost={post}
                  replyPostEnabled={replyPostEnabled}
                  setReplyPostEnabled={setReplyPostEnabled}
                  user={user}
                  threadid={post.threadid}
                  minimal={
                    user?.settings?.feedReplies && post.parentid == 0 && !sticky
                  }
                  textHint={__("Reply on post") + " " + post.threadtitle}
                  onReply={() => {
                    setReplyAfterPostid(0);
                  }}
                />
              ) : null}
            </>
          </ul>
        </li>
      ))}
    </ul>
  );

  if (sticky) {
    return showPosts();
  }

  let title;
  let titleLink;
  if (random) {
    title = __("Random posts");
    titleLink = "/random";
  }
  if (popular && !random && !tag) {
    title = __("Top");
    titleLink = "/top";
  }
  if (!popular && !search && !random && !tag) {
    title = __("Actual");
    titleLink = "/activity";
  }
  if (search && !random && !tag) {
    title = __("Search") + ' "' + search + '"';
    titleLink = "/activity";
  }

  return (
    <div>
      <div className="mainTitle">
        <Filters
          user={user}
          forumid={forumid}
          fetchData={fetchData}
          categorySwitcher={categorySwitcher}
          setCategorySwitcher={setCategorySwitcher}
          oldUnpopularSwitcher={oldUnpopularSwitcher}
          recentPopularSwitcher={recentPopularSwitcher}
          mostCommented={mostCommented}
          periodSwitcher={periodSwitcher}
          postsPeriod={postsPeriod}
          setPostsPeriod={setPostsPeriod}
          title={origin != "user" && title}
          titleLink={titleLink}
          titleClass={random ? "renewPosts" : null}
          titleClick={
            random
              ? (e) => {
                  e.preventDefault();
                  if (fetchData) fetchData(true);
                }
              : null
          }
          titleButton={random && <FontAwesomeIcon icon={faRetweet} />}
          tag={tag}
          tagStatusLoaded={tagStatusLoaded}
          tagSubscribed={tagSubscribed}
          setTagSubscribed={setTagSubscribed}
          showDisplayMode={
            !search &&
            !mostCommented &&
            !displayOnlyComments &&
            !displayOnlyPosts
          }
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
        />
      </div>
      <div className="blocks-main flex row center-row w100p">
        {leftPanels ? (
          <div
            className={
              "leftPostsPanel" + (leftRatingDisplay ? " withLeftContainer" : "")
            }
          >
            {leftPanels}
          </div>
        ) : null}
        {noLimitation ? (
          <AutoScrollable
            className="flex row center-row"
            onBottomScroll={() => {
              if (posts.length == 0) {
                logT("scroll", "skip autoscroll, because zero posts");
                return;
              }
              fetchData();
            }}
          >
            <HistoryScroll
              store={"recentposts" + (source ? `-${source}` : "")}
              preSaveData={(location) => {
                if (
                  user?.settings?.hideViewHistory &&
                  user?.settings?.hideViewHistoryCollapse &&
                  needCheckIgnorePosts.current
                ) {
                  if (
                    location.includes("/threads/") ||
                    location.includes("/post/")
                  ) {
                    if (location.includes("/post/")) {
                      const postid = parseInt(
                        location.replaceAll("/post/", ""),
                      );
                      if (postid > 0) {
                        if (postsMap[postid]) {
                          logT("hidden", "set hidden postid", postsMap[postid]);
                          postsMap[postid].isHidden = true;
                        }
                      }
                    } else {
                      const threadid = parseInt(
                        location.replaceAll("/threads/", ""),
                      );
                      if (threadid > 0) {
                        for (const post of posts) {
                          if (post.threadid == threadid && post.parentid == 0) {
                            logT("hidden", "set hidden postid", post.postid);
                            post.isHidden = true;
                            break;
                          }
                        }
                      }
                    }
                  }
                }
              }}
              saveData={{
                posts: () =>
                  posts.map((post) => _.omit(post, ["parent", "childs"])),
                postsMap: () =>
                  _.keyBy(
                    Object.values(postsMap).map((post) =>
                      _.omit(post, ["parent", "childs"]),
                    ),
                    "postid",
                  ),
                uncollapse: uncollapseMap.current,
                textFullQuote: textFullQuoteMap.current,
                customScrollData,
                tagSubscribed,
                tagStatusLoaded,
                hiddenMap,
                needCheckIgnorePosts: needCheckIgnorePosts.current,
              }}
              onSetScroll={(scrollVal) => {
                collapsedScrollValue.current = scrollVal;
              }}
              onLoadData={(data) => {
                if (data?.uncollapse) {
                  uncollapseMap.current = data.uncollapse;
                }
                if (data?.textFullQuote) {
                  textFullQuoteMap.current = data.textFullQuote;
                }
                if (data?.needCheckIgnorePosts) {
                  needCheckIgnorePosts.current = data.needCheckIgnorePosts;
                }
                if (data?.postsMap) {
                  postsMap = data.postsMap;
                  rebuldPostMap();
                }
                if (data?.posts) {
                  logT(
                    "RecentPosts",
                    "set posts from HistoryScroll =",
                    data.posts,
                  );
                  if (data?.postsMap) {
                    data.posts = data.posts.map(
                      (post) => data.postsMap[post.postid] || post,
                    );
                  }
                  setPosts(data.posts);
                  window.TALKVIOAPI_CANCEL("recentposts");
                }
                if (data?.hiddenMap) {
                  setHiddenMap(data.hiddenMap);
                }
                if (data?.customScrollData && onCustomScrollDataRestore) {
                  onCustomScrollDataRestore(data.customScrollData);
                }
                setTagSubscribed(data.tagSubscribed);
                setTagStatusLoaded(data.tagStatusLoaded);
              }}
            >
              {showPosts()}
            </HistoryScroll>
          </AutoScrollable>
        ) : (
          showPosts()
        )}
      </div>
    </div>
  );
};
export default RecentPosts;
